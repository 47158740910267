export const artisans = [
  "plumber",
  "electrician",
  "mason",
  "cleaner",
  "carpenter",
  "painter",
  "ac-technician",
  "solar-technician",
  "tiler",
  "dstv-installer",
  "fumigator",
  "pop-worker",
  "welder",
  "aluminum-worker",
  "artisan",
];

type ArtisanKey = "artisan";

interface AllowedArtisansValue {
  formattedName: string;
  categoryName: string;
  prompt?: string;
}

type SpecificArtisans = {
  [K in ArtisanKey]: AllowedArtisansValue;
};

type GeneralArtisans = {
  [K: string]: AllowedArtisansValue | undefined;
};

type AllowedArtisans = SpecificArtisans & GeneralArtisans;

export const allowedArtisans: AllowedArtisans = {
  plumber: {
    formattedName: "Plumber",
    categoryName: "Plumbing",
  },
  electrician: {
    formattedName: "Electrician",
    categoryName: "Electrical",
  },
  mason: {
    formattedName: "Mason",
    categoryName: "Masonry",
  },
  cleaner: {
    formattedName: "Cleaner",
    categoryName: "Cleaning",
  },
  carpenter: {
    formattedName: "Carpenter",
    categoryName: "Carpentry",
  },
  painter: {
    formattedName: "Painter",
    categoryName: "Painting",
  },
  "ac-technician": {
    formattedName: "AC Technician",
    categoryName: "Airconditioning",
    prompt: "AC",
  },
  "solar-technician": {
    formattedName: "Solar Technician",
    categoryName: "Solar Power",
    prompt: "solar installation",
  },
  tiler: {
    formattedName: "Tiler",
    categoryName: "Tiling",
  },
  "dstv-installer": {
    formattedName: "DSTV Installer",
    categoryName: "DSTV",
    prompt: "DSTV installation",
  },
  fumigator: {
    formattedName: "Fumigator",
    categoryName: "Fumigation",
  },
  "pop-worker": {
    formattedName: "POP Worker",
    categoryName: "POP",
    prompt: "POP",
  },
  welder: {
    formattedName: "Welder",
    categoryName: "Welding",
  },
  "aluminum-worker": {
    formattedName: "Aluminum Worker",
    categoryName: "Aluminum works",
    prompt: "aluminum works",
  },
  artisan: {
    formattedName: "Artisan",
    categoryName: "Other",
    prompt: "installation, repair or maintenance",
  },
};

export const locations = [
  "lagos",
  "lekki",
  "ikate",
  "ikota",
  "vgc",
  "jakande",
  "agungi",
  "victoria-island",
  "ajah",
  "ikotun",
  "surulere",
  "magodo",
  "ikeja",
  "ikoyi",
  "yaba",
  "ilupeju",
  "shomolu",
  "agor-palace",
  "festac",
  "oniru",
  "chevron",
  "gbagada",
  "nigeria",
  "near-me",
];
