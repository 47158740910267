import { gql } from "@apollo/client";

export const SUBMIT_CART = gql`
  # type jobInput {
  #   $user: UserInput!
  #     $tasks: [CartItem!]!
  #     $location: [jobLocation!]!
  #     $scheduledDate: ScheduledDate!
  # }

  mutation CreateOrder($data: CreateOrderInput!) {
    createOrder(data: $data) {
      status
      message
      data {
        paymentUrl
      }
    }
  }
`;

export const REGISTER_CLIENT = gql`
  mutation registerNewClient($data: RegisterNewClientInput!) {
    registerNewClient(data: $data) {
      status
      message
    }
  }
`;

export const SET_DEFAULT_LOCATION = gql`
  mutation setLocationAsDefault($locationId: String!) {
    setLocationAsDefault(locationId: $locationId) {
      status
      message
    }
  }
`;
