import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Heading,
  HStack,
  Icon,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useApplicationConfig } from "context/ApplicationConfig";
import { set } from "date-fns";
import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { BiSupport } from "react-icons/bi";
import { BsPatchCheckFill } from "react-icons/bs";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Form } from "semantic-ui-react";
import { HOMEOWNER_HIRE_FORM_DATA_LOCAL_STORE_KEY } from "../../../constants";
import { HomeownerHireContext } from "../../../context/homeowners/homeowner-hire.context";
import { MY_CLIENT } from "../../../graphql/query.constants";
import { logEvent } from "../../../helpers/analytics";
import { usePageView } from "../../../hooks/usePageView";
import { isValidEmail } from "../../../lib/utils";
import Loading from "../../../_components/Loading";
import RequestFailed from "../../../_components/RequestFailed";
import {
  HireLayout,
  HireLayoutContent,
  HireLayoutFooter,
  HireLayoutHeader,
} from "./layout";
import { ThemedFormLabel } from "./themed-form-label";
import { ThemedInput } from "./themed-input";

const CLIENT_EXISTS = gql`
  query ClientExist($email: String!) {
    clientExists(email: $email)
  }
`;

const CREATE_HOMEOWNER_REQUEST = gql`
  mutation CreateHomeownerRequest(
    $categoryId: String!
    $otherCategoryText: String
    $description: String
    $email: String
    $firstName: String
    $lastName: String
    $planId: String
  ) {
    createHomeownerRequest(
      categoryId: $categoryId
      otherCategoryText: $otherCategoryText
      description: $description
      email: $email
      firstName: $firstName
      lastName: $lastName
      planId: $planId
    ) {
      id
    }
  }
`;

export const ContactInformation = () => {
  usePageView();

  const {toggles, loading: togglesLoading} = useApplicationConfig();

  const {
    user,
    isLoading: authIsLoading,
    isAuthenticated,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
  } = useAuth0();

  const history = useHistory();

  useEffect(() => {
    getAccessTokenSilently().catch((error) => {
      console.log(error);
    });
  }, []);

  const [clientExists, clientExistsResponse] = useLazyQuery<{
    clientExists: boolean;
  }>(CLIENT_EXISTS, {
    fetchPolicy: "network-only",
  });

  const [createHomeownerRequest, createHomeownerRequestResponse] = useMutation<{
    createHomeownerRequest: { id: string };
  }>(CREATE_HOMEOWNER_REQUEST, {
    onCompleted: ({ createHomeownerRequest: { id } }) => {
      localStorage.removeItem(HOMEOWNER_HIRE_FORM_DATA_LOCAL_STORE_KEY);
      
      history.push(`/homeowners/hire/${id}`);
    },
  });

  const { formData, canCreateRequest, isNew, isLoading } =
    useContext(HomeownerHireContext);

  const [
    getMyClient,
    { data: myClientData, loading: myClientLoading, error: myClientError },
  ] = useLazyQuery(MY_CLIENT, {
    onCompleted: (data) => {
      if (data.myClient) {
        handleCreateHomeownerRequest(data.myClient.email, data.myClient.firstName, data.myClient.lastName);
      } else if (formData.firstName && formData.lastName) {
        handleCreateHomeownerRequest(user?.email, formData.firstName, formData.lastName);
      }
    },
  });

  const match = useRouteMatch();

  const [email, setEmail] = React.useState(formData.email || user?.email || "");

  const [firstName, setFirstName] = React.useState(formData.firstName || "");

  const [lastName, setLastName] = React.useState(formData.lastName || "");

  const [showError, setShowError] = React.useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      getMyClient();
    }
  }, [isAuthenticated]);

  const backToPath = formData.isSubscriptionRequest ? match.url.replace(/\/[^/]+$/, "/subscription-details") : match.url.replace(/\/[^/]+$/, "/describe-task");

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setShowError(false);
  };

  const handleCreateHomeownerRequest = (email?: string,
    clientFirstName?: string,
    clientLastName?: string
  ) => {
    if (!canCreateRequest) {
      return;
    }

    createHomeownerRequest({
      variables: {
        categoryId: formData.category!,
        otherCategoryText: formData.otherCategoryText,
        description: formData.description,
        email,
        firstName: clientFirstName,
        lastName: clientLastName,
        planId: formData.plan,
      },
    });
  };

  const handleProceed = () => {
    if (!isValidEmail(email)) {
      setShowError(true);
      return;
    }

    if (formData.isSubscriptionRequest && toggles && toggles?.handle_subscription_payments) {
      if (!firstName || !lastName) {
        setShowError(true);
        return;
      }
    }

    setShowError(false);

    /**
     * If the email is the same as the one in the form data, we do not need to create a new homeowner request
     * We can proceed to the next step
     */
    if (!isNew && email === formData.email) {
      history.push(`${match.url.replace(/\/[^/]+$/, "/address-selector")}`);
    }

    logEvent("homeowner/hire/email_set");

    clientExists({
      variables: {
        email,
      },
      onCompleted: (data) => {
        if (!data.clientExists) {
          if (formData.isSubscriptionRequest) {
            if (isAuthenticated) {
              handleCreateHomeownerRequest(email, firstName, lastName);
              return;
            }

            localStorage.setItem(
              HOMEOWNER_HIRE_FORM_DATA_LOCAL_STORE_KEY,
              JSON.stringify({
                ...formData,
                firstName,
                lastName,
              })
            );

            loginWithRedirect({
              redirectUri: window.location.origin + match.url,
              login_hint: email,
            });
          } else {
            handleCreateHomeownerRequest(email);
          }
        } else {
          localStorage.setItem(
            HOMEOWNER_HIRE_FORM_DATA_LOCAL_STORE_KEY,
            JSON.stringify({
              ...formData,
              firstName,
              lastName,
            })
          );

          loginWithRedirect({
            redirectUri: window.location.origin + match.url,
            login_hint: email,
          });
        }
      },
    });
  };

  const useCurrentlyLoggedInEmail = (email: string) => {
    if (!isNew && email === formData.email) {
      history.push(`${match.url.replace(/\/[^/]+$/, "/address-selector")}`);
      return;
    }

    logEvent("homeowner/hire/email_set");

    handleCreateHomeownerRequest(email);
  };

  const logOutOfCurrentlyLoggedInEmail = () => {
    localStorage.setItem(
      HOMEOWNER_HIRE_FORM_DATA_LOCAL_STORE_KEY,
      JSON.stringify(formData)
    );

    logout({
      returnTo:
        window.location.origin +
        match.url.replace(/\/[^/]+$/, "/contact-information"),
    });
  };

  if (
    authIsLoading ||
    myClientLoading ||
    createHomeownerRequestResponse.loading ||
    togglesLoading
  ) {
    return <Loading />;
  }

  if (createHomeownerRequestResponse.error) {
    return <RequestFailed />;
  }

  if (myClientError || clientExistsResponse.error) {
    return <RequestFailed />;
  }

  //console.log(isAuthenticated)

  if (toggles && !toggles?.handle_subscription_payments) {
    if (isAuthenticated) {
      return (
        <>
          <Helmet>
            <title>
              Contact Information | LaborHack Homeowners - Hire certified artisans
              for your home needs
            </title>
          </Helmet>
          <HireLayout>
            <HireLayoutHeader backTo={backToPath}>NOTICE</HireLayoutHeader>
            <HireLayoutContent>
              <Text>
                {`It looks like you are currently logged into LaborHack service as ${user?.email}. To proceed, please confirm if you'd like to use this email to create your client account. Or logout if you wish to use a different email.`}
              </Text>
            </HireLayoutContent>
            <Stack
              direction="row"
              w="full"
              spacing={4}
              justifyContent="end"
              padding={4}
            >
              <Button
                variant={"outline"}
                colorScheme="flatteredFlamingo"
                onClick={logOutOfCurrentlyLoggedInEmail}
              >
                Log out
              </Button>
              <Button
                colorScheme="green"
                onClick={() => useCurrentlyLoggedInEmail(user?.email!)}
              >
              Yes, I confirm
              </Button>
            </Stack>
          </HireLayout>
        </>
      );
    }
  }

  return (
    <>
      <Helmet>
        <title>
          Contact Information | LaborHack Homeowners - Hire certified artisans
          for your home needs
        </title>
      </Helmet>
      <HireLayout>
        <HireLayoutHeader backTo={backToPath} isLoading={isLoading}>
          Fill in the details below
        </HireLayoutHeader>
        <HireLayoutContent>
          <Stack spacing={8}>
            {
              formData.isSubscriptionRequest && (
                <HStack>
                  <FormControl isInvalid={showError && !firstName}>
                    <ThemedFormLabel>First Name</ThemedFormLabel>
                    <ThemedInput
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      placeholder="Enter your first name"
                    />
                    <FormErrorMessage>Please enter your first name</FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={showError && !lastName}>
                    <ThemedFormLabel>Last Name</ThemedFormLabel>
                    <ThemedInput
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      placeholder="Enter your last name"
                    />
                    <FormErrorMessage>Please enter your last name</FormErrorMessage>
                  </FormControl>
                </HStack>
              )
            }

            <FormControl isInvalid={!isValidEmail(email) && showError}>
              <ThemedFormLabel>Email Address</ThemedFormLabel>
              <ThemedInput
                type="email"
                value={isAuthenticated && !formData.firstName ? user?.email : email}
                isDisabled={isAuthenticated && !formData.firstName}
                onChange={handleEmailChange}
                placeholder="Enter your email address"
              />
              {
                isAuthenticated && !formData.firstName && (
                  <Stack>
                    <FormHelperText>
                      <strong>Notice:</strong> It looks like you are currently logged into LaborHack service with this email. Please proceed if you&apos;d like to use this email to create your client account, or logout if you wish to use a different email. 
                    </FormHelperText>
                    <FormHelperText>
                      <Text
                        decoration={'underline'}
                        cursor={'pointer'}
                        onClick={logOutOfCurrentlyLoggedInEmail}>Logout</Text>
                    </FormHelperText>
                  </Stack>
                )
              }

              <FormErrorMessage>Please valid email</FormErrorMessage>
            </FormControl>

            <Stack
              direction={{
                lg: "row",
                base: "column",
              }}
              border="1px"
              borderColor="gray.100"
              spacing={2}
            >
              <Stack direction="row" align="center" p={3} spacing={4}>
                <Box p={2} rounded="full" backgroundColor="gray.100">
                  <Icon
                    as={BsPatchCheckFill}
                    w={5}
                    h={5}
                    color="flatteredFlamingo.400"
                  />
                </Box>
                <Stack>
                  <Heading size="sm">LaborHack Guarantee</Heading>
                  <Text size="xs" color="gray.600">
                    If you are not satisfied, we&apos;ll make adjustments at no
                    further cost to you.
                  </Text>
                </Stack>
              </Stack>
              <Divider orientation="vertical" />
              <Stack direction="row" align="center" p={3} spacing={4}>
                <Box p={2} rounded="full" backgroundColor="gray.100">
                  <Icon
                    as={BiSupport}
                    w={5}
                    h={5}
                    color="flatteredFlamingo.400"
                  />
                </Box>
                <Stack>
                  <Heading size="sm">Dedicated Support</Heading>
                  <Text size="xs" color="gray.600">
                    Friendly service when you need us, every day of the week.
                  </Text>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </HireLayoutContent>
        <HireLayoutFooter
          isLoading={clientExistsResponse.loading}
          onClick={handleProceed}
        >
          Proceed
        </HireLayoutFooter>
      </HireLayout>
    </>
  );
};
