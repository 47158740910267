import {
  Box,
  Button,
  Grid,
  GridItem,
  Heading,
  Highlight,
  HStack,
  Icon,
  Image,
  Link,
  Stack,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import { Section } from "pages/landing/components/Section";
import React, { FC } from "react";
import { Helmet } from "react-helmet";
import VerifiedSectionicon from "../../assets/eclipsed-verified-icon.svg";
import ACSubscriptionCardImage from "../../assets/ac-subscription-card-image.jpg";
import CleaningSubscriptionCardImage from "../../assets/cleaning-subscription-card-image.jpg";
import FumigationSubscriptionCardImage from "../../assets/fumigation-subscription-card-image.jpg";
import SubscriptionHeroImage from "../../assets/subscription-hero-image.png";
import FAQs from "_components/FAQs";
import { subscriptionsFAQs } from "./faqs/constants";
import CategoriesMarquee from "_components/CategoriesMarquee";
import ProBadge from "../../assets/pro-badge.svg";
import { Link as RouterLink } from "react-router-dom";
import Footer from "_components/Footer";
import { BsArrowRight } from "react-icons/bs";
import { useCategories } from "hooks/useCategories";
import { logEvent } from "helpers/analytics";
import Loading from "_components/Loading";
import { ReviewCardWrapper } from "_components/ReviewCard/ReviewCardWrapper";
import { formatToNaira } from "helpers/utils/funtions";
import Slider from "react-slick";
import { Category } from "pages/dashboard/types";

const reviews = [
  {
    name: "Christiana Okere",
    title: "Homeowner",
    imageSrc: "",
    review: `LaborHack was hassle-free to use. The artisan they 
    sent to my house was knowledgeable and 
    professional. He did a great job. 
    I highly recommend them`,
    rating: 5,
  },
  {
    name: "Sarah Keffas",
    title: "Homeowner",
    imageSrc: "",
    review: `After servicing my Air Conditioners with LaborHack, 
    they performed optimally, almost like brand new ones. 
    Service was great and quiet professional. I highly 
    recommend using LaborHack`,
    rating: 5,
  },
];

const prompts: Record<string, string[]> = {
  Airconditioning: ["AC maintenance", "Air quality improvement"],
  Cleaning: ["Customized schedules"],
  Fumigation: ["Pest control"],
};

export const SubscriptionsLandingPage: FC = () => {
  const carouselSettings = useBreakpointValue({
    base: {
      slidesToShow: 1,
      dots: true,
    },
    lg: {},
  });

  const { categories, loading: categoriesAreLoading } = useCategories();

  const categoriesWithSubscriptionPackages = categories?.filter((category) => {
    return category.packages.length > 0;
  });

  const constructUrl = (category: Category) => {
    return `/homeowners/hire/new?category=${category.id}&is_subscription_request=true&lead_source=subscriptions_landing_page`;
  };

  const formatMinimumPlanPrice = (category: Category) => {
    const packages = category.packages;

    const plans = packages.flatMap(({ plans }) => plans);

    const prices = plans.map(({ costBreakdown }) => costBreakdown.total);

    const minimumPlanPrice = Math.min(...prices);

    const minimumPlanPriceWithoutDecimal = Math.floor(minimumPlanPrice);

    return formatToNaira(minimumPlanPriceWithoutDecimal);
  };

  const handleSubscriptionCTAClick = (category?: string) => {
    logEvent("subscriptions_cta_clicked", {
      category,
    });
  };

  if (categoriesAreLoading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>
          LaborHack Subscriptions - Monthly Home Cleaning, AC Maintenance &
          Fumigation Services
        </title>
      </Helmet>
      <VStack w="full" spacing={0}>
        {/* hero */}
        <Section>
          <VStack w="full" spacing={12}>
            <VStack
              w={{
                base: "full",
                lg: "60%",
              }}
              alignItems="center"
              spacing={{
                base: 6,
                lg: 8,
              }}
            >
              <VStack
                w="full"
                alignItems="center"
                spacing={{
                  base: 3,
                  lg: 4,
                }}
              >
                <Heading
                  as="h1"
                  fontSize={{
                    base: 26,
                    md: 52,
                  }}
                  fontWeight="bold"
                  color="prussianBlue.500"
                  lineHeight={{
                    base: "base",
                    md: "shorter",
                  }}
                  textAlign="center"
                >
                  We’ve Got
                  <br /> Your{" "}
                  <Highlight
                    query={["Home"]}
                    styles={{
                      padding: 0,
                      color: "flatteredFlamingo.500",
                    }}
                  >
                    Home
                  </Highlight>{" "}
                  Covered
                </Heading>
                <Text
                  fontSize={{
                    base: 15,
                    lg: 24,
                  }}
                  lineHeight="base"
                  fontWeight="medium"
                  color="prussianBlue.500"
                  textAlign="center"
                >
                  Enjoy a clean, comfortable home that you love. Our
                  experienced, vetted professionals take care of everything on
                  your schedule.
                </Text>
                <Text
                  fontSize={{
                    base: 15,
                    lg: 24,
                  }}
                  lineHeight="base"
                  fontWeight="medium"
                  color="prussianBlue.500"
                  textAlign="center"
                >
                  Enjoy transparent pricing - no surprises.
                </Text>

                <HStack spacing={4} justifyContent="center" mt={8}>
                  <Link
                    flex={1}
                    as={RouterLink}
                    to="/homeowners/hire/new?is_subscription_request=true&lead_source=subscriptions_landing_page"
                  >
                    <Button
                      colorScheme="flatteredFlamingo"
                      paddingY={{
                        base: 6,
                        lg: 10,
                      }}
                      paddingX={{
                        base: 8,
                        lg: 14,
                      }}
                      fontSize={{
                        base: 15,
                        lg: 22,
                      }}
                      rightIcon={<Icon as={BsArrowRight} w={6} h={6} />}
                      onClick={() => handleSubscriptionCTAClick()}
                    >
                      Get Started Now
                    </Button>
                  </Link>
                </HStack>
              </VStack>
            </VStack>
          </VStack>
        </Section>

        <Stack width="100%">
          <Image
            w={"100%"}
            h={"100%"}
            fit={{
              base: "contain",
              lg: "cover",
            }}
            src={SubscriptionHeroImage}
          />
        </Stack>

        <Section
          bgColor="prussianBlue.50"
          justifyContent="center"
          alignItems="center"
          py={12}
        >
          <VStack
            w="full"
            spacing={{
              base: 12,
              lg: 20,
            }}
            justifyContent="center"
            alignItems="center"
          >
            <HStack w="full" justifyContent="center">
              <Heading
                as="h2"
                fontSize={{
                  base: 22,
                  md: 44,
                }}
                w="80%"
                fontWeight={{
                  base: "medium",
                  md: "bold",
                }}
                lineHeight={{
                  base: "base",
                  md: "shorter",
                }}
                textAlign="center"
              >
                Choose Your Home Service
              </Heading>
            </HStack>
            <Stack w={"full"}>
              <Slider
                dots={false}
                infinite={true}
                speed={3000}
                slidesToShow={3}
                slidesToScroll={1}
                autoplay={true}
                autoplaySpeed={3000}
                pauseOnHover={true}
                {...carouselSettings}
              >
                {categoriesWithSubscriptionPackages &&
                  categoriesWithSubscriptionPackages.map((category, index) => (
                    <Box
                      key={index}
                      px={{
                        base: 2,
                        lg: 4,
                      }}
                    >
                      <VStack
                        w={{
                          base: "full",
                          lg: "99%",
                        }}
                        h={"550px"}
                        rounded="lg"
                        border="1px"
                        borderColor="prussianBlue.50"
                        alignItems="start"
                        spacing={{
                          base: 2,
                          lg: 4,
                        }}
                        bgColor="white"
                        padding={5}
                      >
                        <Stack
                          direction={{
                            base: "row",
                            lg: "column",
                          }}
                          spacing={{
                            base: 4,
                            lg: 8,
                          }}
                          alignItems={{
                            base: "center",
                            lg: "start",
                          }}
                          h={"250px"}
                          mb={4}
                        >
                          <Image src={category.imageUrl} w="full" h="full" />
                        </Stack>
                        <Heading
                          as="h4"
                          fontSize={{
                            base: 18,
                            lg: 25,
                          }}
                          fontWeight="medium"
                        >
                          {category.name}
                        </Heading>
                        <Text
                          color="gray.500"
                          mb={1}
                        >{`Starting from ${formatMinimumPlanPrice(
                          category
                        )}`}</Text>
                        <Box>
                          {prompts[category.name] &&
                            prompts[category.name].map((prompt: any, index) => (
                              <HStack mb={2} key={index}>
                                <Image src={VerifiedSectionicon} w={7} h={7} />
                                <Text
                                  fontSize={{
                                    base: 15,
                                    lg: 18,
                                  }}
                                  lineHeight="base"
                                  fontWeight={{
                                    base: "normal",
                                    lg: "medium",
                                  }}
                                  color="gray.500"
                                  w={{
                                    base: "100%",
                                    lg: "95%",
                                  }}
                                >
                                  {prompt}
                                </Text>
                              </HStack>
                            ))}
                        </Box>
                        <Link
                          as={RouterLink}
                          to={constructUrl(category)}
                          marginTop={"auto"}
                        >
                          <Button
                            data-testid="hire-cta"
                            colorScheme="flatteredFlamingo"
                            variant="solid-dark"
                            w="full"
                            textColor="white"
                            paddingY={{
                              base: 6,
                              lg: 8,
                            }}
                            fontSize={{
                              base: 15,
                              lg: 20,
                            }}
                            rightIcon={<Icon as={BsArrowRight} w={6} h={6} />}
                            onClick={() =>
                              handleSubscriptionCTAClick(category.name)
                            }
                          >
                            Subscribe now
                          </Button>
                        </Link>
                      </VStack>
                    </Box>
                  ))}
              </Slider>
            </Stack>
          </VStack>
        </Section>

        <Section bgColor="#F8F8F8" justifyContent="center" alignItems="center">
          <VStack
            w="full"
            spacing={{
              base: 12,
              lg: 20,
            }}
            justifyContent="center"
            alignItems="center"
          >
            <VStack
              w="full"
              justifyContent="center"
              alignContent="center"
              gap={4}
            >
              <Heading
                as="h2"
                fontSize={{
                  base: 22,
                  md: 44,
                }}
                w="80%"
                fontWeight={{
                  base: "medium",
                  md: "bold",
                }}
                lineHeight={{
                  base: "base",
                  md: "shorter",
                }}
                textAlign="center"
              >
                Enjoy the{" "}
                <Highlight
                  query={["LaborHack"]}
                  styles={{
                    padding: 0,
                    color: "flatteredFlamingo.500",
                  }}
                >
                  LaborHack
                </Highlight>{" "}
                Experience
              </Heading>
              <Text
                fontSize={{
                  base: 16,
                  md: 22,
                }}
                textAlign={"center"}
              >
                We want to make it easy for you to enjoy a clean and comfortable
                home without the hassle. Our services come with insurance and
                the Laborhack Guarantee, ensuring your peace of mind every step
                of the way.
              </Text>
            </VStack>
            <Grid
              templateColumns={{
                base: "repeat(1, 1fr)",
                lg: "repeat(2, 1fr)",
              }}
              gap={8}
            >
              <GridItem w="100%">
                <VStack
                  w={{
                    base: "full",
                    lg: "92%",
                  }}
                  minH={{
                    base: "auto",
                    lg: "full",
                  }}
                  alignItems="start"
                  spacing={{
                    base: 2,
                    lg: 4,
                  }}
                  bgColor="white"
                  padding={5}
                >
                  <Stack
                    direction={{
                      base: "row",
                      lg: "column",
                    }}
                    spacing={{
                      base: 4,
                      lg: 8,
                    }}
                    alignItems={{
                      base: "center",
                      lg: "start",
                    }}
                  >
                    <Image
                      src={VerifiedSectionicon}
                      w={{
                        base: 10,
                        lg: 20,
                      }}
                    />
                    <Heading
                      as="h4"
                      fontSize={{
                        base: 18,
                        lg: 32,
                      }}
                      fontWeight="medium"
                    >
                      Trusted & vetted workers
                    </Heading>
                  </Stack>

                  <Text
                    fontSize={{
                      base: 15,
                      lg: 24,
                    }}
                    lineHeight="base"
                    fontWeight={{
                      base: "normal",
                      lg: "medium",
                    }}
                    color="gray.500"
                    w={{
                      base: "100%",
                      lg: "95%",
                    }}
                  >
                    We understand the importance of having reliable and skilled
                    artisans for your business. We connect you with certified,
                    vetted, and insured artisans to get the job done.
                  </Text>
                </VStack>
              </GridItem>
              <GridItem w="100%">
                <VStack
                  w={{
                    base: "full",
                    lg: "92%",
                  }}
                  minH={{
                    base: "auto",
                    lg: "full",
                  }}
                  alignItems="start"
                  spacing={{
                    base: 2,
                    lg: 4,
                  }}
                  bgColor="white"
                  padding={5}
                >
                  <Stack
                    direction={{
                      base: "row",
                      lg: "column",
                    }}
                    spacing={{
                      base: 4,
                      lg: 8,
                    }}
                    alignItems={{
                      base: "center",
                      lg: "start",
                    }}
                  >
                    <Image
                      src={VerifiedSectionicon}
                      w={{
                        base: 10,
                        lg: 20,
                      }}
                    />
                    <Heading
                      as="h4"
                      fontSize={{
                        base: 18,
                        lg: 32,
                      }}
                      fontWeight="medium"
                    >
                      Peace of Mind
                    </Heading>
                  </Stack>

                  <Text
                    fontSize={{
                      base: 15,
                      lg: 24,
                    }}
                    lineHeight="base"
                    fontWeight={{
                      base: "normal",
                      lg: "medium",
                    }}
                    color="gray.500"
                    w={{
                      base: "100%",
                      lg: "95%",
                    }}
                  >
                    With LaborHack, you can know that trustworthy professionals
                    are working on your property. No stories, no disappointment
                  </Text>
                </VStack>
              </GridItem>
              <GridItem w="100%">
                <VStack
                  w={{
                    base: "full",
                    lg: "92%",
                  }}
                  minH={{
                    base: "auto",
                    lg: "full",
                  }}
                  alignItems="start"
                  spacing={{
                    base: 2,
                    lg: 4,
                  }}
                  bgColor="white"
                  padding={5}
                >
                  <Stack
                    direction={{
                      base: "row",
                      lg: "column",
                    }}
                    spacing={{
                      base: 4,
                      lg: 8,
                    }}
                    alignItems={{
                      base: "center",
                      lg: "start",
                    }}
                  >
                    <Image
                      src={VerifiedSectionicon}
                      w={{
                        base: 10,
                        lg: 20,
                      }}
                    />
                    <Heading
                      as="h4"
                      fontSize={{
                        base: 18,
                        lg: 32,
                      }}
                      fontWeight="medium"
                    >
                      Transparent Pricing
                    </Heading>
                  </Stack>

                  <Text
                    fontSize={{
                      base: 15,
                      lg: 24,
                    }}
                    lineHeight="base"
                    fontWeight={{
                      base: "normal",
                      lg: "medium",
                    }}
                    color="gray.500"
                    w={{
                      base: "100%",
                      lg: "95%",
                    }}
                  >
                    With our transparent pricing, you can rest assured knowing
                    there are no hidden fees or surprises.
                  </Text>
                </VStack>
              </GridItem>
              <GridItem w="100%">
                <VStack
                  w={{
                    base: "full",
                    lg: "92%",
                  }}
                  minH={{
                    base: "auto",
                    lg: "full",
                  }}
                  alignItems="start"
                  spacing={{
                    base: 2,
                    lg: 4,
                  }}
                  bgColor="white"
                  padding={5}
                >
                  <Stack
                    direction={{
                      base: "row",
                      lg: "column",
                    }}
                    spacing={{
                      base: 4,
                      lg: 8,
                    }}
                    alignItems={{
                      base: "center",
                      lg: "start",
                    }}
                  >
                    <Image
                      src={VerifiedSectionicon}
                      w={{
                        base: 10,
                        lg: 20,
                      }}
                    />
                    <Heading
                      as="h4"
                      fontSize={{
                        base: 18,
                        lg: 32,
                      }}
                      fontWeight="medium"
                    >
                      LaborHack Guarantee
                    </Heading>
                  </Stack>

                  <Text
                    fontSize={{
                      base: 15,
                      lg: 24,
                    }}
                    lineHeight="base"
                    fontWeight={{
                      base: "normal",
                      lg: "medium",
                    }}
                    color="gray.500"
                    w={{
                      base: "100%",
                      lg: "95%",
                    }}
                  >
                    If you&apos;re not satisfied, we&apos;ll work to make it
                    right.
                  </Text>
                </VStack>
              </GridItem>
            </Grid>
            <Stack
              w="full"
              direction={{
                base: "column",
                lg: "row",
              }}
              spacing={{
                base: 12,
                lg: 0,
              }}
            ></Stack>
          </VStack>
        </Section>

        <Section bgColor="prussianBlue.500" py={12}>
          <VStack w="full" justifyContent="center" gap={6}>
            <Heading
              as="h2"
              fontSize={{
                base: 22,
                md: 44,
              }}
              w="80%"
              fontWeight={{
                base: "medium",
                md: "bold",
              }}
              lineHeight={{
                base: "base",
                md: "shorter",
              }}
              textAlign="center"
              textColor={"white"}
            >
              By the numbers
            </Heading>
            <Stack
              direction={{
                base: "column",
                lg: "row",
              }}
              width={{
                base: "70%",
                lg: "100%",
              }}
              gap={6}
            >
              <VStack
                bgColor={"white"}
                alignItems={"start"}
                paddingY={2}
                paddingX={4}
                width={{
                  base: "100%",
                  lg: "45%",
                }}
              >
                <Heading
                  as="h3"
                  fontSize={{
                    base: 18,
                    lg: 32,
                  }}
                  fontWeight="semibold"
                >
                  100+
                </Heading>
                <Text
                  fontSize={{
                    base: 15,
                    lg: 24,
                  }}
                  lineHeight="base"
                  fontWeight="medium"
                  color="prussianBlue.500"
                >
                  Home services provided
                </Text>
              </VStack>
              <VStack
                bgColor={"white"}
                alignItems={"start"}
                paddingY={2}
                paddingX={4}
                width={{
                  base: "100%",
                  lg: "45%",
                }}
              >
                <Heading
                  as="h3"
                  fontSize={{
                    base: 18,
                    lg: 32,
                  }}
                  fontWeight="semibold"
                >
                  3
                </Heading>
                <Text
                  fontSize={{
                    base: 15,
                    lg: 24,
                  }}
                  lineHeight="base"
                  fontWeight="medium"
                  color="prussianBlue.500"
                >
                  Years of experience in the
                  <br />
                  home services industry
                </Text>
              </VStack>
              <VStack
                bgColor={"white"}
                alignItems={"start"}
                paddingY={2}
                paddingX={4}
                width={{
                  base: "100%",
                  lg: "45%",
                }}
              >
                <Heading
                  as="h3"
                  fontSize={{
                    base: 18,
                    lg: 32,
                  }}
                  fontWeight="semibold"
                >
                  4500+
                </Heading>
                <Text
                  fontSize={{
                    base: 15,
                    lg: 24,
                  }}
                  lineHeight="base"
                  fontWeight="medium"
                  color="prussianBlue.500"
                >
                  Experienced pros in our
                  <br />
                  network across the country
                </Text>
              </VStack>
            </Stack>
          </VStack>
        </Section>

        {/* Testimonials */}
        <Section bgColor="white">
          <VStack
            marginLeft="auto"
            marginRight="auto"
            spacing={{
              base: 8,
              lg: 12,
            }}
          >
            <Heading
              as="h2"
              fontSize={{
                base: 22,
                md: 44,
              }}
              fontWeight={{
                base: "medium",
                md: "bold",
              }}
              color="prussianBlue.500"
              lineHeight={{
                base: "base",
                md: "shorter",
              }}
              textAlign="center"
              width={{
                base: "80%",
                lg: "60%",
              }}
            >
              Hear from our happy clients
            </Heading>
            <ReviewCardWrapper items={reviews} />
          </VStack>
        </Section>

        <Section>
          <VStack
            zIndex={99}
            px={5}
            py={{
              base: 8,
              lg: 20,
            }}
            spacing={{
              base: 6,
              lg: 8,
            }}
            w="full"
            maxW="container.xl"
            alignItems="center"
          >
            <Heading
              as="h2"
              fontSize={{
                base: 22,
                md: 44,
              }}
              fontWeight="bold"
              color="prussianBlue.500"
              lineHeight={{
                base: "base",
                md: "shorter",
              }}
              textAlign={{
                base: "center",
              }}
            >
              Frequently Asked Questions
            </Heading>

            <Box
              w="full"
              paddingX={{
                base: 4,
                lg: 16,
              }}
              paddingY={{
                base: 4,
                lg: 8,
              }}
              bgColor="flatteredFlamingo.50"
            >
              <FAQs items={subscriptionsFAQs} />
            </Box>
          </VStack>
        </Section>
        <CategoriesMarquee />
        <HStack w="full" bgColor="prussianBlue.500" justifyContent="center">
          <VStack
            px={5}
            py={{
              base: 8,
              lg: 20,
            }}
            spacing={{
              base: 6,
              lg: 12,
            }}
            w="full"
            maxW="container.xl"
            alignItems="start"
          >
            <HStack
              w="full"
              justifyContent={{
                base: "start",
                lg: "space-between",
              }}
            >
              <Heading
                as="h5"
                fontSize={{
                  base: "2xl",
                  md: "5xl",
                }}
                fontWeight="normal"
                color="white"
                lineHeight={{
                  base: "base",
                  md: "shorter",
                }}
              >
                <strong>Ready for Stress-Free</strong>
                <br />
                <strong>Home Maintenance?</strong>
              </Heading>
              <Image
                src={ProBadge}
                alt="LaborHack professional badge"
                w={128}
                display={{
                  base: "none",
                  lg: "block",
                }}
              />
            </HStack>
            <HStack w="full">
              <Link
                flex={1}
                as={RouterLink}
                to="/homeowners/hire/new?is_subscription_request=true&lead_source=subscriptions_landing_page"
              >
                <Button
                  w={{
                    base: "full",
                    lg: "40%",
                  }}
                  py={6}
                  size={{
                    base: "lg",
                    md: "xl",
                  }}
                  onClick={() => handleSubscriptionCTAClick()}
                >
                  Get Started Now
                </Button>
              </Link>
            </HStack>
          </VStack>
        </HStack>
      </VStack>
      <Footer />
    </>
  );
};
