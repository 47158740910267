import { Button, Heading, Stack, Text } from "@chakra-ui/react";
import React, { useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import { HireLayout, HireLayoutContent, HireLayoutHeader } from "./layout";

import HireConfirmation from "assets/homeowners/hire-confirmation.svg";
import { useAuth0 } from "@auth0/auth0-react";
import { useApplicationConfig } from "../../../context/ApplicationConfig";
import { Helmet } from "react-helmet";
import { usePageView } from "../../../hooks/usePageView";
import { HomeownerHireContext } from "../../../context/homeowners/homeowner-hire.context";

export const Confirmation = () => {
  usePageView();

  const { loginWithRedirect, user } = useAuth0();

  const { toggles } = useApplicationConfig();

  const { formData, isLoading } = useContext(HomeownerHireContext);

  const handleProceed = async () => {
    localStorage.removeItem("homeowner_lead_source");
    
    loginWithRedirect({
      redirectUri: process.env.REACT_APP_HOMEOWNER_DASHBOARD_URL,
      login_hint: user?.email || formData.email || undefined,
    });
  };

  return (
    <>
      <Helmet>
        <title>
          Confirmation | LaborHack Homeowners - Hire certified artisans for your
          home needs
        </title>
      </Helmet>
      <HireLayout>
        <HireLayoutHeader isLoading={isLoading} />
        <HireLayoutContent>
          <Stack spacing={6}>
            <img src={HireConfirmation} />
            <Stack>
              <Heading as="h2" size="lg" textAlign="center">
                Congratulations
              </Heading>
              <Text fontSize="lg" textAlign="center">
                We have received your request and we will get in touch with you
                shortly.
              </Text>
            </Stack>

            {toggles?.enable_client_dashboard_access ? (
              <Button
                colorScheme="flatteredFlamingo"
                size="lg"
                onClick={handleProceed}
                isLoading={isLoading}
              >
                Proceed to Dashboard
              </Button>
            ) : (
              <Button
                as={RouterLink}
                to="/homeowners"
                colorScheme="flatteredFlamingo"
                size="lg"
                isLoading={isLoading}
              >
                Close
              </Button>
            )}
          </Stack>
        </HireLayoutContent>
      </HireLayout>
    </>
  );
};
