import { Box, Divider, HStack, Image, Stack, Text } from "@chakra-ui/react";
import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useRouteMatch } from "react-router-dom";
import { HomeownerHireContext } from "../../../context/homeowners/homeowner-hire.context";
import { usePageView } from "../../../hooks/usePageView";
import {
  HireLayout,
  HireLayoutContent,
  HireLayoutFooter,
  HireLayoutHeader,
} from "./layout";
import SubscriptionPaymentIcon from "../../../assets/subscription-payment.svg";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import Loading from "_components/Loading";
import { HomeownerRequest } from "types";
import RequestFailed from "_components/RequestFailed";
import { logEvent } from "helpers/analytics";
import { GET_INVOICE_PAYMENT_LINK } from "../../../pages/invoice/graphql/mutation";

const GET_NEXT_INVOICE_PAYMENT = gql`
  query GetNextInvoicePayment($id: String!) {
    nextInvoicePayment(invoiceId: $id) {
      discount
      total
      subTotal
      transactionFee
    }
  }
`;

const CONFIRM_HOMEOWNER_REQUEST_PAYMENT = gql`
  mutation ConfirmHomeownerRequestPayment(
    $homeownerRequestId: String!
    $transactionReference: String!
  ) {
    confirmHomeownerRequestPayment(
      homeownerRequestId: $homeownerRequestId
      transactionReference: $transactionReference
    ) {
      id
      isPaid
      firstName
      email
      plan {
        id
      }
    }
  }
`;

const GET_PAYMENT_COST = gql`
  query GetHomeownerRequestPaymentCost($id: String!) {
    getHomeownerRequestPaymentCost(id: $id) {
      charge
      price
      totalCost
    }
  }
`;

const formatPrice = (price: number) => {
  const priceString = price.toFixed(2);

  const priceStringWithSeparator = priceString.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ","
  );

  return `NGN ${priceStringWithSeparator}`;
};

export const SubscriptionPayment = () => {
  usePageView();

  const match = useRouteMatch();

  const [paymentFailed, setPaymentFailed] = React.useState(false);

  // grab reference and status from homeowner hire context

  const {
    formData,
    setFormData,
    isLoading,
    categories,
    transactionReference,
    homeownerRequest,
  } = useContext(HomeownerHireContext);

  const [getNextInvoicePayment, getNextInvoicePaymentHandle] = useLazyQuery<{
    nextInvoicePayment: {
      discount: number;
      total: number;
      subTotal: number;
      transactionFee: number;
    };
  }>(GET_NEXT_INVOICE_PAYMENT);

  const [getPaymentLink, getPaymentLinkHandle] = useMutation<{
    getInvoicePaymentLink: {
      status: string;
      message: string;
      data: {
        paymentUrl: string;
      };
    };
  }>(GET_INVOICE_PAYMENT_LINK, {
    onCompleted: (data) => {
      if (data.getInvoicePaymentLink.data.paymentUrl) {
        window.open(data.getInvoicePaymentLink.data.paymentUrl, "_self");
      }
    },
  });

  useEffect(() => {
    if (homeownerRequest?.invoice?.id) {
      getNextInvoicePayment({
        variables: {
          id: homeownerRequest.invoice.id,
        },
      });
    }
  }, [homeownerRequest?.invoice?.id]);

  const [confirmHomeownerRequestPayment, confirmHomeownerRequestPaymentResult] =
    useMutation<{
      confirmHomeownerRequestPayment: HomeownerRequest;
    }>(CONFIRM_HOMEOWNER_REQUEST_PAYMENT, {
      onCompleted: (data) => {
        if (!data.confirmHomeownerRequestPayment.isPaid) {
          setPaymentFailed(true);
        }
      },
    });

  useEffect(() => {
    if (transactionReference && formData.id) {
      confirmHomeownerRequestPayment({
        variables: {
          homeownerRequestId: formData.id,
          transactionReference,
        },
      });
    }
  }, [transactionReference, formData.id]);

  const category = formData?.category;

  const categoryObject = categories?.find((cat) => cat.id === category);

  const nextInvoicePayment =
    getNextInvoicePaymentHandle.data?.nextInvoicePayment;

  const backToPath = match.url.replace(/\/[^/]+$/, "/address-selector");

  const handlePayment = () => {
    logEvent("homeowner_subscription_payment_initiated", {
      homeownerRequestId: formData.id,
      category: categoryObject?.name,
    });

    getPaymentLink({
      variables: {
        invoiceId: homeownerRequest?.invoice?.id,
        providerId: "paystack",
      },
    });
  };

  if (
    isLoading ||
    confirmHomeownerRequestPaymentResult.loading ||
    getNextInvoicePaymentHandle.loading ||
    !formData
  ) {
    return <Loading />;
  }

  const homeownerRequestIsPaid =
    confirmHomeownerRequestPaymentResult.data?.confirmHomeownerRequestPayment
      .isPaid;

  if (homeownerRequestIsPaid) {
    setFormData(
      {
        ...formData,
      },
      "start-date"
    );
  }

  if (getNextInvoicePaymentHandle.error || getPaymentLinkHandle.error) {
    <RequestFailed onRetry={window.location.reload} />;
  }

  if (paymentFailed) {
    return <RequestFailed onRetry={window.location.reload} />;
  }

  const transactionFee = nextInvoicePayment?.transactionFee || 0;
  const total = nextInvoicePayment?.total || 0;

  const discountValue = nextInvoicePayment?.discount || 0;

  const amountToPay = total + transactionFee;

  return (
    <>
      <Helmet>
        <title>
          Subscription Payment | LaborHack Homeowners - Hire certified artisans
          for your home needs
        </title>
      </Helmet>
      <HireLayout>
        <HireLayoutHeader backTo={backToPath} isLoading={false}>
          Summary
        </HireLayoutHeader>
        <HireLayoutContent>
          <Stack justifyContent="center" alignItems="center">
            <Image
              src={SubscriptionPaymentIcon}
              alt="subscription payment icon"
              height={104}
              width={104}
            />
            <Text fontWeight={500} fontSize={25} mb={10}>
              {formatPrice(amountToPay)}
            </Text>
            <Divider />
            <Box
              width="100%"
              display="flex"
              flexDirection="column"
              backgroundColor="#F4F4F4"
              p="20px"
              mt="15px"
              mb="15px"
              rowGap="15px"
            >
              <HStack justifyContent="space-between">
                <Text fontWeight={400} fontSize={16}>
                  {categoryObject?.name}
                </Text>
                <Text fontWeight={500} fontSize={16}>
                  {formatPrice(total + discountValue)}
                </Text>
              </HStack>
              {discountValue > 0 && (
                <HStack justifyContent="space-between">
                  <Text fontWeight={400} fontSize={16}>
                    Discount ({homeownerRequest?.invoice?.appliedDiscount?.code}
                    )
                  </Text>
                  <Text fontWeight={500} fontSize={16}>
                    -{formatPrice(discountValue)}
                  </Text>
                </HStack>
              )}
              <HStack justifyContent="space-between">
                <Text fontWeight={400} fontSize={16}>
                  Transaction Charge
                </Text>
                <Text fontWeight={500} fontSize={16}>
                  {formatPrice(transactionFee)}
                </Text>
              </HStack>
              <HStack justifyContent="space-between">
                <Text fontWeight={400} fontSize={16}>
                  Total
                </Text>
                <Text fontWeight={500} fontSize={16}>
                  {formatPrice(amountToPay)}
                </Text>
              </HStack>
            </Box>
            <Divider />
          </Stack>
        </HireLayoutContent>
        {(!transactionReference || !homeownerRequestIsPaid) && (
          <HireLayoutFooter
            isLoading={isLoading || getPaymentLinkHandle.loading}
            onClick={handlePayment}
            height="61px"
          >
            {`Pay ${formatPrice(amountToPay)}`}
          </HireLayoutFooter>
        )}
      </HireLayout>
    </>
  );
};
