import { Button, Icon, Stack, Text } from "@chakra-ui/react";
import React, { useContext } from "react";
import { useRouteMatch } from "react-router-dom";
import { HireLayout, HireLayoutHeader } from "./layout";
import { HomeownerHireContext } from "../../../context/homeowners/homeowner-hire.context";
import { BsChevronRight } from "react-icons/bs";
import { BiMap } from "react-icons/bi";
import { logEvent } from "../../../helpers/analytics";
import { Helmet } from "react-helmet";
import { usePageView } from "../../../hooks/usePageView";
import { useApplicationConfig } from "context/ApplicationConfig";

export const SavedAddressSelector = () => {
  usePageView();

  const match = useRouteMatch();

  const {toggles, loading: togglesLoading} = useApplicationConfig();

  const { formData, setFormData, isLoading, client } = useContext(HomeownerHireContext);

  const backToPath = match.url.replace(/\/[^/]+$/, "");

  const handleProceed = (id: string) => {
    if (!location) {
      return;
    }

    logEvent("homeowner/hire/saved_address_selected");

    let nextPath;

    if (toggles && toggles?.handle_subscription_payments && !!formData.plan) { 
      nextPath = "subscription-payment"
    } else {
      nextPath = "start-date"
    }

    setFormData(
      {
        addressId: id,
      },
      nextPath
    );
  };

  return (
    <>
      <Helmet>
        <title>
          Saved Addresses | LaborHack Homeowners - Hire certified artisans for
          your home needs
        </title>
      </Helmet>
      <HireLayout spacing={0}>
        <HireLayoutHeader backTo={backToPath} isLoading={isLoading}>
          Saved addresses
        </HireLayoutHeader>

        <Stack spacing={0} width="full" pb={8}>
          {client?.addresses.map((address) => (
            <Button
              key={address.id}
              variant="ghost"
              justifyContent="space-between"
              width="full"
              py={4}
              height="fit-content"
              rightIcon={<Icon as={BsChevronRight} w={4} h={4} />}
              disabled={isLoading}
              onClick={() => handleProceed(address.id)}
            >
              <Stack direction="row" align="center" spacing={4}>
                <Icon
                  as={BiMap}
                  w={{
                    base: 4,
                    lg: 6,
                  }}
                  h={{
                    base: 4,
                    lg: 6,
                  }}
                />
                <Stack align="flex-start" spacing={0}>
                  <Text>{address.address}</Text>
                  <Text fontSize="sm" color="gray.500">
                    {address.city}, {address.state}
                  </Text>
                </Stack>
              </Stack>
            </Button>
          ))}
        </Stack>
      </HireLayout>
    </>
  );
};
