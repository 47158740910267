import { FAQProps } from "_components/FAQs/FAQs";

export const subscriptionsFAQs: FAQProps["items"] = [
  {
    title: "What is LaborHack's subscription service?",
    body: "LaborHack's subscription service offers regular and reliable cleaning, HVAC maintenance, and fumigation services to ensure your space remains clean, comfortable, and pest-free.",
  },
  {
    title: "How does the subscription service work?",
    body: "You can choose a subscription plan that fits your needs. Once subscribed, our professional team will provide scheduled services, ensuring your space is always maintained.",
  },
  {
    title: "What types of cleaning services are included in the subscription?",
    body: "Our cleaning services include general cleaning,  window cleaning, carpet cleaning, and more, tailored to your specific needs.",
  },
  {
    title: "How often will the cleaning services be provided?",
    body: "Depending on your subscription plan, cleaning services can be scheduled weekly, bi-weekly, or monthly.",
  },
  {
    title: "Do I need to provide cleaning supplies and equipment?",
    body: "No, our team comes fully equipped with all necessary supplies and equipment.",
  },
  {
    title: "What does the AC/HVAC maintenance service include?",
    body: "Our AC/HVAC maintenance includes routine inspections, filter changes, system cleaning, and minor repairs to ensure your AC/HVAC system runs efficiently.",
  },
  {
    title: "How frequently will AC/HVAC maintenance be performed?",
    body: "AC/HVAC maintenance is typically performed quarterly, but can be adjusted based on your subscription plan.",
  },
  {
    title: "What if my AC/HVAC system requires major repairs?",
    body: "If major repairs are needed, our technicians will provide a detailed assessment and recommend the best course of action. It will be charged for separately.",
  },
  {
    title: "What pests are covered under the fumigation service?",
    body: "Our fumigation service covers common pests such as insects, rodents, and other unwanted pests.",
  },
  {
    title: "How often should fumigation be done?",
    body: "Regular fumigation is recommended quarterly, but can be adjusted based on the severity of the pest problem and your specific needs.",
  },
];