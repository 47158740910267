import {
  Button,
  Heading,
  HStack,
  Text,
  VStack,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  Stack,
  FormControl,
  Input,
  FormLabel,
  Link,
  InputGroup,
} from "@chakra-ui/react";
import React from "react";
import { Helmet } from "react-helmet";
import { FaArrowRight } from "react-icons/fa";
import Footer from "../../_components/Footer";
import { Section } from "../landing/components/Section";
import PromoBg from "./../../assets/fixme/promo-bg.jpg";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useLogServerEvent } from "../../hooks/useLogServerEvent";

export default function Page() {
  const { search } = useLocation();

  const query = new URLSearchParams(search);

  const injectedReferralCode = query.get("referredBy");
  const openSignupByDefault = query.get("openSignup") === "true";

  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: openSignupByDefault,
  });

  const { loading, logServerEvent } = useLogServerEvent();

  const [email, setEmail] = React.useState("");

  const [referralCode, setReferralCode] = React.useState("");

  return (
    <>
      <Helmet>
        <title>FixMe | LaborHack</title>
        <meta
          name="description"
          content="Get free home and office repairs worth ₦10,000 when you sign up for our services using a referral code."
        />
      </Helmet>
      <Modal isOpen={isOpen} onClose={onClose} size="2xl" isCentered>
        <ModalOverlay />
        <ModalContent borderRadius={0}>
          <Stack w="full" align="center" spacing={4}>
            <Stack width="full">
              <Stack width="full">
                <Stack direction="row" p={5}>
                  <Heading
                    as="h2"
                    fontSize={{
                      base: "xl",
                      lg: "2xl",
                    }}
                    fontWeight="medium"
                  >
                    Get Started
                  </Heading>
                </Stack>
              </Stack>
            </Stack>
            <Stack width="full" p={5} gap={4}>
              <FormControl isRequired>
                <FormLabel
                  mb={2}
                  color="gray.500"
                  fontFamily="Inter"
                  fontWeight="normal"
                >
                  Email Address
                </FormLabel>
                <Input
                  rounded="none"
                  variant="filled"
                  size="lg"
                  placeholder="ciroma@example.com"
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormControl>

              <Stack gap={2} alignItems="end">
                <FormControl>
                  <FormLabel
                    mb={2}
                    color="gray.500"
                    fontFamily="Inter"
                    fontWeight="normal"
                  >
                    Referral Code
                  </FormLabel>
                  <InputGroup>
                    <Input
                      rounded="none"
                      variant={injectedReferralCode ? "unstyled" : "filled"}
                      {...(injectedReferralCode && {
                        py: 2,
                        px: 4,
                      })}
                      size="lg"
                      type="text"
                      placeholder="Enter referral code"
                      readOnly={!!injectedReferralCode}
                      onChange={(e) => setReferralCode(e.target.value)}
                      value={injectedReferralCode || referralCode}
                    />
                  </InputGroup>
                </FormControl>
                {!injectedReferralCode && (
                  <Link
                    as={RouterLink}
                    to={"/auth/register/homeowner"}
                    textDecoration="underline"
                  >
                    Don&apos;t have a referral code? Click here
                  </Link>
                )}
              </Stack>

              <Button
                colorScheme="flatteredFlamingo"
                size="lg"
                isLoading={loading}
                onClick={() => {
                  logServerEvent(
                    "STARTED_FIX_ME_REGISTRATION",
                    {
                      referralCode: injectedReferralCode || referralCode,
                      email,
                    },
                    () => {
                      window.open(
                        `${
                          process.env.REACT_APP_HOMEOWNER_DASHBOARD_URL
                        }?signUpEmail=${email}&referredBy=${
                          injectedReferralCode || referralCode
                        }`,
                        "_self"
                      );
                    }
                  );
                }}
              >
                Create Account
              </Button>
            </Stack>
          </Stack>
        </ModalContent>
      </Modal>

      <div className="flex flex-col">
        <Section bgColor="flatteredFlamingo.500">
          <VStack w="full" spacing={12}>
            <VStack
              w={{
                base: "full",
                lg: "60%",
              }}
              alignItems="center"
              spacing={{
                base: 6,
                lg: 8,
              }}
            >
              <VStack
                w="full"
                alignItems="center"
                spacing={{
                  base: 3,
                  lg: 4,
                }}
              >
                <Heading
                  as="h1"
                  fontSize={{
                    base: 26,
                    md: 52,
                  }}
                  fontWeight="bold"
                  color="white"
                  lineHeight={{
                    base: "base",
                    md: "shorter",
                  }}
                  textAlign="center"
                >
                  <span className=" proportional-nums text-prussianBlue-500 font-[AeonikPro]">
                    ₦10,000
                  </span>{" "}
                  Free LaborHack Voucher
                </Heading>
                <Text
                  fontSize={{
                    base: 15,
                    lg: 24,
                  }}
                  lineHeight="base"
                  fontWeight="medium"
                  color="white"
                  textAlign="center"
                >
                  Sign up and request a service on LaborHack today to enjoy free
                  home and office repairs worth ₦10,000! Plus, get the added
                  peace of mind with our LaborHack Guarantee. This exclusive
                  offer is available for a limited time only!
                </Text>
              </VStack>

              <HStack w="full" spacing={4} justifyContent="center">
                <Button
                  as="a"
                  colorScheme="flatteredFlamingo"
                  bgColor="flatteredFlamingo.900"
                  paddingY={{
                    base: 6,
                    lg: 10,
                  }}
                  paddingX={{
                    base: 8,
                    lg: 14,
                  }}
                  fontSize={{
                    base: 15,
                    lg: 22,
                  }}
                  className="hover:text-white"
                  rightIcon={<Icon as={FaArrowRight} w={6} h={6} ml={4} />}
                  onClick={onOpen}
                >
                  Get Started
                </Button>
              </HStack>
            </VStack>
          </VStack>
        </Section>
        <img
          src={PromoBg}
          alt="FixMe Hero"
          className="w-full h-[200px] sm:h-[400px] object-cover"
        />
        <Footer />
      </div>
    </>
  );
}
