import { gql } from "@apollo/client";

export const GET_CATEGORIES = gql`
  query Categories($includeCustomPackages: Boolean) {
    categories {
      id
      name
      proTitle
      imageUrl
      requestCount
      priority
      packages(includeCustomPackages: $includeCustomPackages) {
        name
        maximumCalls
        baseInterval
        id
        isCustom
        plans {
          id
          interval
          discount
          costBreakdown {
            discount
            subTotal
            tax
            total
          }
          price {
            id
          }
        }
      }
    }
  }
`;

export const GET_ALL_SERVICES = gql`
  query GetAllServices {
    getAllServices {
      allCategories {
        id
        name
        proTitle
        imageUrl
        requestCount
        subCategoryIds
      }
      allSubCategories {
        id
        categoryId
        name
        imageUrl
        description
        requestCount
        taskIds
      }
      allServiceTasks {
        id
        categoryId
        subCategoryId
        name
        description
        imageUrl
        requestCount
        estimatedCost
      }
    }
  }
`;

export const GET_USER_BY_EMAIL = gql`
  query GetUserByEmail($email: String!) {
    getUserRecordByEmail(email: $email) {
      id
      email
    }
  }
`;

export const GET_USER_BY_PHONENUMBER = gql`
  query GetUserByPhoneNumber($phoneNumber: String!) {
    getUserRecordByPhoneNumber(phoneNumber: $phoneNumber) {
      id
      phoneNumber
    }
  }
`;

export const GET_BUSINESS_BY_EMAIL = gql`
  query GetBusinessByEmail($email: String!) {
    businessByEmail(email: $email) {
      id
      email
      phoneNumber
    }
  }
`;

export const VERIFY_PAYMENT = gql`
  query VerifyPayment($transactionReference: String!) {
    verifyPayment(transactionReference: $transactionReference) {
      status
    }
  }
`;

export const POST_ORDER_VERIFY_PAYMENT = gql`
  query PostOrderVerifyPayment($transactionReference: String!) {
    postOrderVerifyPayment(transactionReference: $transactionReference) {
      status
      data {
        name
        email
        phoneNumber
      }
    }
  }
`;

export const GET_ALL_TRADESMEN = gql`
  query GetAllTradesmen {
    listAllTradesmen {
      status
      message
      data {
        id
        userId
        firstName
        lastName
        email
        phoneNumber
        imageUrl
        blocked
        location {
          buildingNumber
          streetName
          location
          area
          city
          landmark
        }
        categoryId
        level
        rewardPoints
        accountNumber
        bankCode
        recipientCode
      }
    }
  }
`;

export const GET_TRADESMAN_BY_REF_CODE = gql`
  query GetTradesmanByReferralCode($referralCode: String!) {
    getTradesmanByReferralCode(referralCode: $referralCode) {
      status
      message
      data {
        id
        firstName
        lastName
        email
        phoneNumber
        categoryId
        level
        rating
      }
    }
  }
`;

export const GET_PREVIOUSLY_USED_TRADESMEN = gql`
  query GetMyPreviouslyUsedTradesmen {
    getMyPreviouslyUsedTradesmen {
      message
      status
      data {
        assignmentDate
        rating
        tradesman {
          id
          firstName
          lastName
          level
          categoryId
        }
      }
    }
  }
`;

export const GET_MY_LOCATION = gql`
  query GetMyLocations {
    getMyLocations {
      status
      message
      data {
        defaultLocationId
        locations {
          buildingNumber
          streetName
          city
          landmark
          area
          location
          id
        }
      }
    }
  }
`;

export const MY_CLIENT = gql`
  query MyClient {
    myClient {
      id
      firstName
      lastName
      email
      phoneNumber
    }
  }
`;
