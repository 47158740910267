import {
  Box,
  FormControl,
  FormLabel,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { useRouteMatch } from "react-router-dom";
import { HomeownerHireContext } from "../../../context/homeowners/homeowner-hire.context";
import { logEvent } from "../../../helpers/analytics";
import { formatToNaira } from "../../../helpers/utils/funtions";
import { usePageView } from "../../../hooks/usePageView";
import {
  HireLayout,
  HireLayoutContent,
  HireLayoutFooter,
  HireLayoutHeader,
} from "./layout";
import { Select, SingleValue, components } from "chakra-react-select";
import { SubscriptionPackage, Plan } from "pages/dashboard/types";

const formatPackageLabel = (pkg: SubscriptionPackage | undefined) => {
  if (!pkg) return "";

  const intervalLabel = {
    MONTHLY: "month",
    QUARTERLY: "quarter",
    BIANNUALLY: "six months",
    YEARLY: "year",
  }[pkg.baseInterval];

  const callsLabel =
    pkg.maximumCalls === 1 ? "once" : `${pkg.maximumCalls} times`;

  return `${pkg.name} - ${callsLabel} ${
    pkg.baseInterval !== "BIANNUALLY" ? "a" : "in"
  } ${intervalLabel}`;
};

const formattedIntervals: Record<string, string> = {
  MONTHLY: "Monthly",
  QUARTERLY: "Quarterly",
  BIANNUALLY: "Biannually",
  YEARLY: "Yearly",
};

const DiscountLabel = ({ discount }: { discount: string }) => {
  return (
    <Box display="inline-block" backgroundColor="lavender" px={3} py={1} ml={5}>
      <Text color="purple.500">Save {discount}%</Text>
    </Box>
  );
};

export const SubscriptionDetails = () => {
  usePageView();

  const match = useRouteMatch();

  const { formData, setFormData, isLoading, categories } =
    useContext(HomeownerHireContext);

  const categoriesWithSubscriptionPackages =
    categories?.filter((cat) => cat.packages.length > 0) || [];

  const category = categoriesWithSubscriptionPackages.find(
    (category) => category.id === formData.category
  );

  const packages =
    category?.packages.filter(
      (subscriptionPackage) => subscriptionPackage.isCustom !== true
    ) || [];

  const [subscriptionPackage, setSubscriptionPackage] = useState<
    SingleValue<{ value: string; label: string } | null>
  >(
    formData.subscriptionPackage
      ? {
          value: formData.subscriptionPackage,
          label:
            formatPackageLabel(
              packages.find((pkg) => pkg.id === formData.subscriptionPackage)
            ) ?? "",
        }
      : null
  );

  const plans = subscriptionPackage
    ? packages.find((pkg) => pkg.id === subscriptionPackage.value)?.plans
    : [];

  const [plan, setPlan] = useState<SingleValue<{
    value: string;
    label: string;
  }> | null>(
    formData.plan
      ? {
          value: formData.plan,
          label:
            formattedIntervals[
              plans?.find((plan) => plan.id === formData.plan)?.interval ?? ""
            ] ?? "",
        }
      : null
  );

  const planPrice = plans?.find((plan) => plan.id === formData.plan)
    ?.costBreakdown.total;

  const [selectedCategory, setSelectedCategory] = useState<SingleValue<{
    value: string;
    label: string;
  }> | null>(
    formData.category
      ? {
          value: formData.category,
          label:
            categories?.find((cat) => cat.id === formData.category)?.name ?? "",
        }
      : null
  );

  const formattedPrice = planPrice ? formatToNaira(Math.ceil(planPrice)) : "";

  const canProceed = !!selectedCategory && !!subscriptionPackage && !!plan;

  const handleProceed = () => {
    logEvent("homeowner/hire/subscription_details_set", {
      subscriptionPackage: subscriptionPackage?.label,
      plan: plan?.label,
    });

    setFormData(
      {
        ...formData,
        subscriptionPackage: subscriptionPackage?.value,
        plan: plan?.value,
      },
      "contact-information"
    );
  };

  const handleCustomSubscriptionClick = () => {
    logEvent("homeowner/hire/custom_subscription_details");

    setFormData({ ...formData }, "custom-subscription-details");
  };

  const CustomPlanLabel = (props: any) => {
    const planInFocus = plans?.find((plan) => plan.id === props.data.value);

    const discount = planInFocus?.discount;

    return (
      <components.Option {...props}>
        <Box display="flex" alignItems="center">
          <Text>{props.data.label}</Text>
          {!!discount && (
            <DiscountLabel discount={(discount * 100).toString()} />
          )}
        </Box>
      </components.Option>
    );
  };

  let backToPath = match.url.replace(/\/[^/]+$/, "/is-subscription-request");

  const leadSource = localStorage.getItem("homeowner_lead_source");

  if (leadSource === "subscriptions_landing_page") {
    backToPath = match.url.replace(/\/[^/]+$/, "/select-category");
  }

  return (
    <>
      <Helmet>
        <title>
          Describe Task | LaborHack Homeowners - Hire certified artisans for
          your home needs
        </title>
      </Helmet>
      <HireLayout>
        <HireLayoutHeader backTo={backToPath} isLoading={isLoading}>
          Subscription Request
        </HireLayoutHeader>
        <HireLayoutContent>
          <Stack spacing={5}>
            <FormControl>
              <FormLabel color={"#A9A9A9"}>
                What type of service do you need?
              </FormLabel>
              <Select
                value={selectedCategory}
                placeholder="Select category"
                onChange={(value) =>
                  setSelectedCategory(() => {
                    setFormData({
                      ...formData,
                      category: value!.value,
                    });

                    setSubscriptionPackage(null);

                    setPlan(null);

                    return value;
                  })
                }
                options={categoriesWithSubscriptionPackages!.map((cat) => ({
                  value: cat.id,
                  label: cat.name,
                }))}
              />
            </FormControl>
            <FormControl>
              <FormLabel color={"#A9A9A9"}>
                What subscription package would you like?
              </FormLabel>
              <Select
                value={
                  formData.subscriptionPackage
                    ? {
                        value: formData.subscriptionPackage,
                        label:
                          formatPackageLabel(
                            packages.find(
                              (pkg) => pkg.id === formData.subscriptionPackage
                            )
                          ) ?? "",
                      }
                    : subscriptionPackage
                }
                placeholder="Select a subscription package"
                onChange={(value) => {
                  setFormData({
                    ...formData,
                    subscriptionPackage: value!.value,
                  });

                  setSubscriptionPackage(value);

                  setPlan(null);
                }}
                options={packages.map((pkg) => ({
                  value: pkg.id,
                  label: formatPackageLabel(pkg),
                }))}
              />
            </FormControl>
            {subscriptionPackage && (
              <FormControl>
                <FormLabel color={"#A9A9A9"}>
                  How often would you like to make payments?
                </FormLabel>
                <Select
                  value={
                    formData.plan
                      ? {
                          value: formData.plan,
                          label:
                            formattedIntervals[
                              plans?.find((plan) => plan.id === formData.plan)
                                ?.interval ?? ""
                            ],
                        }
                      : plan
                  }
                  components={{ Option: CustomPlanLabel }}
                  placeholder="Select a payment duration"
                  onChange={(value) =>
                    setPlan(() => {
                      setFormData({
                        ...formData,
                        plan: value!.value,
                      });

                      return value;
                    })
                  }
                  options={
                    plans
                      ? plans.map((plan) => ({
                          value: plan.id,
                          label: formattedIntervals[plan?.interval ?? ""],
                        }))
                      : []
                  }
                />
              </FormControl>
            )}
            {plan && (
              <FormControl>
                <FormLabel color={"#A9A9A9"}>Cost of service</FormLabel>
                <Textarea
                  value={formattedPrice}
                  fontWeight={500}
                  textColor={"#000000"}
                  size={"lg"}
                  backgroundColor={"#F2F2F2"}
                  alignContent={"center"}
                  readOnly
                />
              </FormControl>
            )}
          </Stack>
        </HireLayoutContent>

        <Text
          fontSize="medium"
          fontWeight="400"
          width="100%"
          p="0 17.5px 0 17.5px"
        >
          Looking for a custom subscription package that perfectly fits your
          needs?{" "}
          <Text
            onClick={handleCustomSubscriptionClick}
            style={{
              color: "inherit",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            Click here.
          </Text>
        </Text>
        <HireLayoutFooter isDisabled={!canProceed} onClick={handleProceed}>
          Proceed
        </HireLayoutFooter>
      </HireLayout>
    </>
  );
};
3;
